/* eslint-disable max-classes-per-file */
import { CONCURRENT_CALLS_NUMBER, ExecutingPool } from './ExecutingPool';

const executingPool = new ExecutingPool(CONCURRENT_CALLS_NUMBER);

class Node {
  next: any;

  // id: number;

  isCalled: boolean;

  call: () => void;

  constructor(call: () => void) {
    this.next = null;
    this.call = async () => {
      await executingPool.add(() => {
        this.isCalled = true;
        call();
      });
    };
  }
}

class LoadingQueue {
  private head: any;

  private tail: any;

  private subscribers: any[] = [];

  private length: number;

  private loadingQueueProgress: number;

  public loaded: boolean;

  private static instance: LoadingQueue;

  private constructor() {
    this.head = null;
    this.tail = null;
    this.length = 0;
    this.loadingQueueProgress = 0;
    this.loaded = false;
  }

  private triggerLoaded() {
    if ((this.loadingQueueProgress === 100 || this.loadingQueueProgress === 99) && this.length === 0) {
      this.loadingQueueProgress = 100;
      setTimeout(() => {
        this.loaded = true;
        this.subscribers.forEach((cb) => cb(this.loaded));
      }, 500);
    }
  }

  // progress is a number from 0 to 100
  setLoadingQueueProgress(progress: number) {
    if (progress === 100 && this.length > 0) {
      // queue is not empty, so loading almost done
      this.loadingQueueProgress = 99;
    } else {
      this.loadingQueueProgress = progress;
    }
    this.triggerLoaded();
    this.subscribers.forEach((cb) => cb(this.loadingQueueProgress));
  }

  enqueue(value: () => any) {
    const node = new Node(value);
    // node.id = this.length;

    if (this.head) {
      this.tail.next = node;
      this.tail = node;
    } else {
      this.head = node;
      this.tail = node;
    }

    this.length += 1;

    if (this.length <= CONCURRENT_CALLS_NUMBER) {
      // it will run only 4 first items, next are going to be added further
      node.call();
    }
  }

  public static getInstance(): LoadingQueue {
    if (!LoadingQueue.instance) {
      LoadingQueue.instance = new LoadingQueue();
    }

    return LoadingQueue.instance;
  }

  // eslint-disable-next-line consistent-return
  private getNext(head) {
    const { next, isCalled } = head;
    if (!isCalled) {
      return head;
    }
    if (next && next.isCalled) {
      return this.getNext(next);
    }
    if (next && !next.isCalled) {
      return next;
    }
  }

  dequeue() {
    this.length -= 1;

    if (this.head) {
      this.head = this.getNext(this.head);
      if (this.head?.call) {
        this.head.call();
      }
    }
    this.triggerLoaded();
  }

  isEmpty() {
    return this.length === 0;
  }

  subscribe(cb: (loadingQueueProgress: number) => void) {
    this.subscribers.push(cb);
  }
}

export default LoadingQueue;
